// en.js
const enTranslations = {
    main: 'TAMRAT',
    main2:'We offer a wide variety of high-quality original dates. Browse our website now to explore our diverse collection of dates, including Sukkari dates, Ajwa dates, Khlas dates, and many more. We provide fast delivery service to all parts of the world, and we guarantee the quality of our dates at all times. Shop now and get the best dates.',
    main3:'SHOP NOW',

    home1:'Most Loved',
    home2:'Best Sellers',
    home3:'Quick Shop',
    home4:'More Details',
    home5:'add to cart',
    home6:'View More Products',


    home7:'OUR DATES',
    home8:'Get the best types of dates from local farmers in the Kingdom of Saudi Arabia, and enjoy high quality and unique packaging to ensure that the dates reach you in the best condition. Enjoy a wonderful shopping experience and choose from our diverse collection of fresh dates, including Sukkari and Ajwa dates. We are committed to providing you with the best types of dates, and we make sure to present them to you in elegant packaging. Shop now and get fresh and delicious dates straight from local farmers in the Kingdom of Saudi Arabia.',
    home9:'SHOP NOW',
    home10:'Dates',
    home11:'A legacy of thousands of years',
    home12:'SHOP NOW',
    home13:'Values of generosity and hospitality',
    home14:'Important nutrient',
    home15:'WHY TAMRAT',
    home16:'High Quality Dates',
    home17:'Fresh Dates',
    home18:'Fresh Dates',
    home19:'SHOP NOW',
    home20:'Gift your loved one with a special assortment of dates in different varieties and sizes',
    home21:'SHOP NOW',
    home22:'Date Benefits',
    home23:'HIGH IN FIBER',
    home24:'ANTIOCIDANT',
    home25:'GLUTEN FREE',
    home26:'GLUTEN FREE',

    header1:'Home',
    header2:'Dates',
    header3:'Ajaw Date',
    header4:'Sukari Date',
    header5:'Majhool Date',
    header6:'Mabroom Date',
    header7:'Sagie Date',
    header8:'Date sizes',
    header9:'Blog',
    header10:'Contact us',

    sar:'SAR',
    reviews:'Reviews',
    price:'price: ',
    status:'Status',
    in:'Available',
    out:'Unavailable',

    qty:'Quantity',
    add:'Add to cart',
    back:'Go Back',
    cart1:'Shopping Cart',
    cart2:'Your cart is empty',
    sub:'Subtotal',
    items:'items',
    checkout:'Proceed To Checkout',


    step1:'Sign In',
    step2:'Shipping',
    step3:'Payment',
    step4:'Place Order',

    shipping1:'Shipping',
    shipping2:'Address',
    shipping3:'Enter address',
    shipping4:'City',
    shipping5:'Enter city',
    shipping15:'Select city',
    shipping6:'Postal Code',
    shipping7:'Enter postal code',
    shipping8:'Country',
    shipping9:'Select a country',
    shipping10:'Next',

    payment1:'Payment Method',
    payment2:'Select Method',
    payment3:'Electronic payment',
    payment4:'Bank transfers',

    order1:'Shipping',
    order2:'Address:',
    order3:'Payment Method',
    order4:'Method:',
    order5:'Order Items',
    order6:'Order Summary',
    order7:'Items',
    order8:'Shipping',
    order9:'Discount',
    order10:'Total',
    order11:'Place Order',

    auth1:'Login and Registration',
    auth2:'Login With SMS',
    auth3:'Login With Email',
    login:'Login',
    register:'Registeration',
    enterPhone:'Enter your phone number',
    enterEmail:'Enter your Email',
    continue:'Continue',
    new:'Creat New Account',
    checkOtp:'Check OTP Validity',
    enterOtp:'Enter Otp',
    check:'Check',


    faqTitle: "Frequently Asked Questions",
    faqQuestion1: "Dates are sugar dense fruit; how can it be healthy?",
    faqAnswer1: "Dates, in their original form, differ significantly from any other form of sugar. Our body absorbs the sugar in dates a lot slower than refined sugar, due to its high fiber content. This results in a sustained increase in blood glucose compared to a spike when refined sugar is consumed. Dates' sugar requires no need for insulin to be produced, making them a healthy sweet. The high fiber structure, along with high sugar content, causes an instant energy boost without causing harm to your body. Be wary: once the original form of dates is altered (for instance, putting the dates in a food mixer), fiber will disappear, and our body will no longer distinguish the type of sugar we are consuming, requiring our pancreas to do extra work. That's why \"energy balls\" containing dates are not that healthy.",
    faqQuestion2: "What are the potential health benefits of eating dates?",
    faqAnswer2: "We have discovered scientific information about the health benefits and pharmaceutical effects of date palm published in medical journals such as Medline, SID, Pubmed, and Google scholar. We found more than 15 pharmacological effects that dates and its seed can offer. Articles published about the date palm tree show traits like antioxidant, anti-inflammatory, anti-diabetic, progress of pregnancy and neurologic effects, sexual stimulator, and lipid effect beside many more.",
    faqQuestion3: "What are the different types of dates and how do they differ?",
    faqAnswer3: "There are more than 600 date varieties grown worldwide, all of which vary in taste, texture, color, and even nutritional benefits. Each date type has different character, nuance, and layers of flavor. For example, Medjool dates or Mejhool - Mejhool means unknown in Arabic - has an overpowering sweetness and leaves a syrupy film in the eater’s mouth. This variety is the most known type of date consumed in the UK, Europe, and North America. Other popular varieties of dates are the fresh Sukkari dates, found mainly in Saudi Arabia. Sukkari dates have a light and soft appearance with a creamy and nutty flavor, which, unlike Medjool, has a subtle sweetness. There's also Khudri dates, a soft and dark date that's popular among Asian communities. The Khllas date – quintessence in English - is another variety, which is the ultimate date of Saudi Arabia. The Khllas date has a complex taste that can only be appreciated by letting the dates slowly release its flavor in your mouth. It has a mix of honey, caramel, and nutty flavors infused with a rich note of toffee. Ajwa date is dark, soft, and sweet and is cultivated in West Arabia. The Ajwa date variety has great medicinal properties. It’s the most popular and expensive date fruit type that belongs to the city of AlMadinah and its adjoining areas in Saudi Arabia. Ajwa date is the healthiest and the most nutritious among all other date type varieties.",
    faqQuestion4: "When do you eat dates?",
    faqAnswer4: "Dates are a great snack anytime of the day, morning, afternoon, or evening. Dates can be ideal with your morning coffee or tea or before/after your workout.",
  

    "contact": {
        "title": "Be in Touch With Us",
        "description": "We are Always Happy to Reach Us Out",
        "location": "Location",
        "locationDetails": "Saudi Arabia - Jeddah",
        "phoneNumber": "Phone Number",
        "email": "E-mail",
        "beInTouch": "Be in Touch",
        "name": "Name",
        "emailPlaceholder": "Email",
        "phoneNumberPlaceholder": "Phone Number",
        "message": "Message",
        "send": "Send"
      },

      "Tamrat Dates": "Tamrat Dates",
      "About Us": "About Us",
      "Our Products": "Our Products",
      "From Farm to Table": "From Farm to Table",
      "Benefits of Dates": "Benefits of Dates",
      "History of Dates": "History of Dates",
      "About The Company": "About The Company",
      "Contact Us": "Contact Us",
      "Frequently Asked Questions": "Frequently Asked Questions",
      "Hiring": "Hiring",
      "Ajwa Date": "Ajwa Date",
      "Sukari Date": "Sukari Date",
      "Sagie Date": "Sagie Date",
      "Majhool Date": "Majhool Date",
      "Mabroom Date": "Mabroom Date",
      "Commercial Register No. 4030299562": "Commercial Register No. 4030299562",
      "Privacy Policy": "Privacy Policy",
      "Return Policy": "Return Policy",
      "Sitemap": "Sitemap",
      "© 2023 Tamrat Dates All Rights Reserved": "© 2024 Tamrat Dates All Rights Reserved",

      "bankName": "Bank Name",
  "bankCode": "Bank Code",
  "accountNumber": "Account Number",
  "ibanNumber": "IBAN Number",
  "sabb": "SABB",
  "sabbSari": "SABBSARI",

  place1:'Order',
  place2:'Shipping',
  place3:'Name: ',
  place4:'Email: ',
  place5:'Address: ',
  place6:'Not Delivered',
  place7:'Payment Method',
  place8:'Method: ',
  place9:'Not Paid',
  place10:'Order Items',

  war:'The payment receipt has been submitted and is being reviewed',
  ajwaTitle:'Organic Ajwa Al Madina Dates',
  mabroomTitle:'Mabrom Natural Dates',
  majhoolTitle:'The luxurious and distinctive Majdoul Dates',
  sagieTitle:'Sagie Date',
  sukariTitle:'Natural and Fresh Sukari Dates',
  
  freeShipping:'Free shipping for orders above SAR 250',

  header20:'Main Menu',

  header21:'Language',
  header22:'Logout',
  header24:'All Dates',

  acc1:'Shopping Cart',
  acc2:'Total: ',
  acc3:'Go to cart',

  metaTitle:'Tamrat Dates: Discover our wide range of Sukkari dates,Sagai dates,Ajwa dates and more.',
  metaDes:'TAMRAT , the fruits of the date palm tree are one of oldest cultivated fruits and known for their abundance of health benefits and healing powers. These fruits are high in energy and fibre and packed with essential vitamins and minerals',

  coupon1:'Do you have a coupon ?',
  coupon2:'coupon code',
  coupon3:'Apply',
  payConfirm:'Payment and order confirmation',
  sumbitRe:'Upload payment receipt',

  reImage:'Choose an image',
  news1:'Subscribe to the newsletter',
  news2:'email address',
  news3:'subscribe',

  success1:'Your payment was completed successfully. Thank you for choosing Tamrat',
  success2:'Go to the order page',
  success3:'Go to Home',

  fail1:'Payment failed. Please try again.',

  box:'Box - one piece , the minimum order quantity is 50 boxes',
  box1:'Box - one piece',

  kg:'Kilogram',
  half:'Half kilogram',
  seven:'Box - 7 Pieces',

  sizePage:'Sizes of date boxes',
  faqPage:'Frequently Asked Questions',
  find:'Search a product ...',
  resu:'search results for :',
  val:'Please log in or register to continue',
  val2:'Login or Register',
  
  checkOtpNew:'otp has sent to ',

  registeration1:'Name',
  registeration2:'Email',
  registeration3:'Enter your phone number',
  registeration4:'Register',
  registeration5:'Already have an account ?',
  sim:'Related Products',
  enterName:'Enter your name',

  account1:'User Profile',
  account2:'Name',
  account3:'Email',
  account4:'Phone',
  account5:'My Orders',
  account6:'profile',
  account7:'logout',
  download:'Download the invoice',
  paidDone:'Payment completed successfully',
  delDone:'Delivered',

  pr1:'DATE',
  pr2:'TOTAL',
  pr3:'PAID',
  pr4:'DELIVERED',
  pr5:'Details',
  noOrders:'There is no orders yet.',
  place120:'The shipment was prepared and a bill of lading was issued',

  shipform1:'I will receive the shipment myself',
  shipform2:'Someone else will receive the shipment',
  shipform3:'Send as a gift to someone',
  shipform4:'Name',
  shipform5:'Enter a name',
  shipform6:'Phone',
  shipform7:'Enter a phone',
  shipform8:'Message',
  shipform9:'Enter a Message',
  orderType:'Receiving the order',
  GiftMessage:'Message Content',
  de:'The customer will receive the order himself',
  de1:'Someone else will receive the order',
  de2:'Send the order as a gift',
  uimage:'Click here to upload an image of the payment receipt',


};

export default enTranslations;
